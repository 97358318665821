@import "../styles/index.scss";

$mobileNavHeight: 53px;
.MobileNav {
  @include hideOnNonHandhelds;
  @include pagePadding;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: $mobileNavHeight;
  z-index: 5;

  display: flex;
  align-items: center;

  @include hairline(bottom);
  background-color: white;
  transition: background-color 0.15s linear;

  &:not(.Scrolled) {
    background-color: transparent;
    @include cancelHairline;
  }

  .Logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    line-height: 0;
  }

  .LeftItems,
  .RightItems {
    display: flex;
    align-items: center;
  }

  .LeftItems {
    flex-grow: 1;

    .MenuButton {
      @include margin(right, 1);
      line-height: 0;
    }
  }

  .RightItems {
    flex-grow: 0;
    justify-content: flex-end;

    .Progress {
      display: flex;
      align-items: center;

      &:not(.Collapsed):not(.Expanded) {
        margin-right: scaleUnit(-0.5);
      }

      &.Collapsed, &.Expanded {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        height: $mobileNavHeight;
        justify-content: center;
        background-color: white;
        z-index: 1;
        @include hairline(bottom);
        $baseTransition: height 1500ms #{$expoOut}, padding-top 1500ms #{$expoOut}, border-bottom-left-radius 1s #{$expoOut}, border-bottom-right-radius 1s #{$expoOut}, opacity 100ms linear 100ms;
        transition: $baseTransition;

        &.ProgressDrawer {
          &-enter {
            top: -1 * $mobileNavHeight;
          }

          &-enter-active {
            top: 0;
            transition: top 500ms #{$expoOut}, #{$baseTransition};
          }

          &-exit {
            top: 0;
            opacity: 1;
          }

          &-exit-active {
            top: -1 * $mobileNavHeight;
            height: $mobileNavHeight;
            opacity: 0;
            transition: top 500ms #{$expoOut}, #{$baseTransition};
          }
        }
      }

      &.Expanded {
        align-items: flex-start;
        padding-top: 24px;
        height: 464px;
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
        overflow: hidden;
      }
    }

    // Nudge the sign in button into the gutter.
    button {
      @include margin(right, -0.5);
    }
  }

  .CurrentSectionLabel {
    @include margin(right, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .Section,
    .Subsection {
      @include captionStyle;
      margin: 0;
      color: $gray90;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    .Section {
      -webkit-line-clamp: 2;
    }

    &.WithSubsection {
      .Section {
        -webkit-line-clamp: 1;
      }
    }

    .Subsection {
      -webkit-line-clamp: 1;
    }
  }
}

.MobileSideMenu {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  z-index: 200;
  display: flex;

  .MobileSideMenuContents {
    position: relative;
    max-width: $desktopNavColumnWidth;
    background-color: white;
    display: flex;
    padding-bottom: env(safe-area-inset-bottom);
    overflow: scroll;

    .CloseButton {
      position: absolute;
      top: 10px;
      right: 17px; // Ungraceful: manually aligning with the center of the radial progress indicators below.
    }
  }

  .Scrim {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($gray90, 0.7);
  }

  @mixin out {
    .MobileSideMenuContents {
      transform: translateX(-100%);
    }

    .Scrim {
      opacity: 0;
    }
  }

  @mixin in {
    .MobileSideMenuContents {
      transform: translateX(0);
    }

    .Scrim {
      opacity: 1;
    }
  }

  &-enter {
    @include out;
  }

  &-enter-active {
    @include in;
  }

  &-exit {
    @include in;
  }

  &-exit-active {
    @include out;
  }


  &-enter-active,
  &-exit-active {
    .MobileSideMenuContents {
      transition: transform 400ms $expoOut 0ms;
    }

    .Scrim {
      transition: opacity 400ms $expoOut 0ms;
    }
  }

  &-enter-active {
    .MobileSideMenuContents {
      transition-delay: 50ms;
    }
  }

  &-exit-active {
    .Scrim {
      transition-delay: 50ms;
    }
  }
}

.EssayRoot .MobileNav {
  &:not(.Scrolled) {
    .IconButton {
      line {
        stroke: $primaryExtraDark;
      }
    }
  }
}