@import "../styles/index.scss";

@mixin NavList {
  padding-left: 0;

  li {
    @include labelStyle;
    list-style: none;
  }

  a {
    color: $gray70;
  }

  &.Active, .Active {
    &>a {
      color: $primaryDark;
    }
  }

  &.Disabled, .Disabled {
    color: $gray50;
    cursor: not-allowed;
  }
}

.NavContainer {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  &>* {
    pointer-events: auto;
  }

  .DesktopSidebarContainer {
    @include hideOnHandhelds;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: $desktopNavColumnWidth;
    overflow: scroll;
    display: flex;
  }
}