@import "../styles/index.scss";

.RemainingIndicator {
  display: flex;
  align-items: center;

  &.Incomplete>span {
    @include labelSmallStyle;
    @include margin(right, 0.5);
    padding-top: 1px;
    color: $gray40;
  }

  &.Complete {
    &>span {
      @include headingLargeStyle;
      color: $primaryDark;
    }

    &>svg {
      stroke: $primaryDark;
      @include margin(left, 0.75);
      margin-top: -2px;
      animation: scale-up 1s $expoOut both;
    }
  }
}