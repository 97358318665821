@import "../styles/index.scss";
@import "../index.scss";

.FrontPage {
  .BlockFieldContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
    //isolation: isolate;
    background-color: white;
  }

  .FadeOverlay {
    background: radial-gradient(
      55.88% 64.88% at 50% 40%,
      #fbfbfb 0%,
      #f5f5f5 4.69%,
      #f1f1f1 24.48%,
      rgba(0, 0, 0, 0) 110%
    );
    mix-blend-mode: color-dodge;
    position: relative;
    top: -100vh;
    left: 0;
    height: 100vh;
    @include respond-to(non-handhelds) {
      width: calc(100vw - #{$desktopNavColumnWidth});
    }
    @include respond-to(handhelds) {
      width: 100vw;
      background: radial-gradient(
        65.88% 54.88% at 25% 80%,
        #fbfbfb 0%,
        #f5f5f5 5.69%,
        rgba(0, 0, 0, 0) 110%
      );
    }
  }

  .FrontPageContents {
    @include respond-to(non-handhelds) {
      @include essayBodyColumnLayout;
    }
    @include respond-to(handhelds) {
      @include respond-to(non-shorties) {
        max-width: 500px;
        height: 100vh;
        margin-left: auto;
        margin-right: auto;
        padding-left: $handheldColumnMargin;
        padding-right: $handheldColumnMargin;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    @include respond-to(handhelds) {
      position: absolute;
      bottom: $handheldColumnMargin;
      left: $handheldColumnMargin;
      right: $handheldColumnMargin;
    }

    h1 {
      font-family: Cabin, sans-serif;
      font-weight: bold;
      color: $primaryExtraDark;
      font-size: 36px;
      line-height: 42px;
      @include margin(bottom, 1.5);
      @include margin(top, 2);

      @include respond-to(handhelds) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.003em;
        max-width: 280px;
        @include margin(bottom, 1);
      }
    }

    p {
      max-width: 660px;
      font-family: $UIFont;
      font-size: 18px;
      line-height: 28px;

      @include respond-to(handhelds) {
        font-size: 16px;
        line-height: 22px;
        @include margin(bottom, 1);
        max-width: 500px;
      }
    }

    .MnemonicMedium {
      @include hideOnHandhelds;
    }

    .BottomRow {
      @include respond-to(non-handhelds) {
      max-width: 660px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        p {
          width: $desktopBodyColumnWidth - 285px - 64px;
          display: inline-block;
          min-width: 40%;
          margin-right: 16px;
        }
      }

      button {
        @include respond-to(non-handhelds) {
          flex-grow: 1;
          height: 62px;
          font-size: 22px;
          text-align: center;
          display: inline-block;
          margin-top: 12px;
          vertical-align: top;
          @include padding(left right, 2);
        }

        @include respond-to(handhelds) {
          font-size: 16px;
          width: 147px;
          height: 45px;
          margin-left: -4px;
          text-align: center;
          display: block;
        }
      }
    }

    .LogoLine {
      @include hideOnHandhelds;
      max-width: 655px;

      @include margin(top, 3.5);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      svg {
        @include margin(right, 2);
      }

      .Byline {
        margin-top: 9px;
        font-family: $UIFont;
        font-size: 14px;
        flex-shrink: 0;
        text-align: right;

        a {
          color: inherit;
        }
      }
    }
  }
}
