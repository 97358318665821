@mixin labelStyle {
  font-family: $UIFont;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.006em;
  -webkit-font-smoothing: antialiased;
}

@mixin labelSmallStyle {
  font-family: $UIFont;
  font-size: 11px;
  font-weight: normal;
  line-height: 15px;
  letter-spacing: 0.006em;
  // -webkit-font-smoothing: antialiased;  
}

@mixin labelExtraSmallStyle {
  font-family: $UIFont;
  font-size: 9px;
  font-weight: normal;
  line-height: 12px;
  letter-spacing: 0.008em;
  // -webkit-font-smoothing: antialiased;  
}

@mixin interfaceCopyStyle {
  font-family: $UIFont;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  --paragraphSpacing: 12px;
  letter-spacing: -0.007em;
}

@mixin captionStyle {
  font-family: $UIFont;
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0.010em;
  --paragraphSpacing: 22px;
}

@mixin captionSmallStyle {
  font-family: $UIFont;
  font-size: 9px;
  font-weight: normal;
  line-height: 13px;
  letter-spacing: 0.011em;
}

@mixin headingLargeStyle {
  font-family: $UIFont;
  font-size: 17px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: -0.006em;
  -webkit-font-smoothing: antialiased;
  color: $primaryExtraDark;
}

@mixin headingSmallStyle {
  font-family: $UIFont;
  font-size: 15px;
  line-height: 19px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  color: $gray80;
}