@import "../styles/index.scss";
@import "./Nav.scss";

$desktopEssayProgressIndicatorHeight: 360px;

.SiteMenu {
  display: flex;
  flex-direction: column;

  // backdrop-filter: blur(5px);

  // logo
  svg {
    flex-shrink: 0;
  }

  @include padding(top, 1);
  @include padding(left, 1);
  @include padding(right, 1);
  
  a {
    transition: opacity 100ms linear;
  }
  &.ShowingOverlay {
    &>svg, a, .Disabled, .Progress {
      opacity: 0.1 !important;
    }
  }

  ul.EssayList {
    @include NavList;
    margin-left: 0;
    flex-grow: 1;

    &>li {
      @include margin(top, 1.5);
      @include margin(bottom, 1.5);
      min-height: 36px;

      display: flex;
      flex-wrap: wrap;
      
      &>a,
      &>span {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
      }
      
      justify-content: space-between;
      
      a {
        max-width: 190px;
      }

      &.Current {
        display: block;
        .TableOfContents {
          height: $desktopEssayProgressIndicatorHeight;
          overflow: scroll;
          padding-bottom: 164px;

          scrollbar-width: none;
          -ms-overflow-style: none;
          &::-webkit-scrollbar {
            width: 0px;
            background: transparent; /* make scrollbar transparent */
          }        
        }
        max-width: 170px;
        // margin-bottom: 32px;
      }  
    }

    .TableOfContents {
      flex-basis: 100%;
      padding-left: 0;
      @include margin(top, 0.5);
      margin-bottom: 0;

      li {
        @include labelSmallStyle;
        @include margin(top, 0.5);
        @include margin(bottom, 0.5);
        color: $gray60;

        &:first-of-type {
          margin-top: 0;
        }

        ul {
          @include padding(left, 0.5);
          @include margin(bottom, 0.5);

          li {
            @include margin(top, 0.25);
            @include margin(bottom, 0.25);
            @include labelExtraSmallStyle;
            max-width: 170px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .BottomMaterial {
    flex-grow: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    button {
      @include margin(left, -0.5);
      margin-top: 0;
      margin-bottom: 0;
    }

    .ReviewRow {
      @include NavList;
      @include padding(bottom, 0.5);

      a,
      span {
        @include labelStyle;
      }

      span.ReviewStatus {
        @include captionSmallStyle;
        @include margin(left, 0.5);
      }

      &.Disabled {
        user-select: none;
        cursor: not-allowed;

        span {
          color: $gray40;
        }
      }
    }

    .PatreonSection {
      position: relative;
      @include hairline(top, $hairlineColor, 0px, -$handheldColumnMargin);
      @include padding(top, 1);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      button {
        @include margin(right, 1);
        flex-grow: 0;
      }

      .PatreonExplanation,
      .SponsorThanks {
        margin-bottom: 0;
        @include captionSmallStyle;
        color: $gray60;
      }

      .PatreonExplanation {
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: 0;
      }

      .SponsorThanks {
        @include margin(top, 1);
        @include margin(bottom, 1);
        display: block;
        flex-grow: 1;
        a {
          color: inherit;
          text-decoration: underline;
          text-decoration-color: $gray20;
          white-space: nowrap;
        }
      }
    }
  }

  .EssayProgress {
    @include margin(top, 0.5);
    position: absolute;
    transform: scale(0.84);
    left: 14px;
    transform-origin: top left;
    pointer-events: none;
    transition: opacity 100ms linear;

    .EssayIndicatorFlowFloat {
      position: absolute;
      top: calc(50px - #{scaleUnit(3)});
      left: calc(30px - #{scaleUnit(2)});
      right: 0;
      bottom: -30px;
      clip-path: polygon(0% 100%, 1.0000px 319.9070px, 58.6000px 305.5920px, 116.2000px 284.3640px, 173.0000px 237.9070px, 233.5000px 151.9070px, 281.5000px 4.9074px, 100% 0%, 100% 100%);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 55%, white 90%);
    }
    &.BeforeHero {
      .EssayProgressIndicator {
        --outlineColor: transparent !important;
      }
      .EssayIndicatorFlowFloat {
        background: transparent;
      }
    }

    .CoachMarkOverlayContainer {
      position: absolute;
      top: 0;
      transform: scale(calc(1/0.84));
      transform-origin: top left;
    }
  
  }
}