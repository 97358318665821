@import "../styles/index.scss";

.IconButton {
  @include margin(top, -1);
  @include margin(right, -1);
  @include margin(bottom, -1);
  @include margin(left, -1);
  @include padding(top, 1);
  @include padding(right, 1);
  @include padding(bottom, 1);
  @include padding(left, 1);
  border-radius: $borderRadius;
  user-select: none;
  
  line {
    stroke: $gray40;
  }

  &:active {
    line {
      stroke: $primaryKey;
    }
  }
}